html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
a {
  text-decoration: none;
  color: #fff;
}
.link {
  font-weight: bold;
  text-decoration: underline;
}
.unmarked {
  font-weight: unset;
  text-decoration: none;
}
.controlArrow.prev {
  left: 50px;
}
.controlArrow.next {
  right: 50px;
}
#infoMenu li a {
  color: #fff;
}

/* Fullpage configuration */
.section,
.slide {
  top: 0;
  left: 0;
  visibility: visible;
  overflow: hidden;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Parallax container configuration */
.content-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-flow: column nowrap;
  color: #fff;
  font-family: Montserrat;
  background-color: #27408d;
  /*background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url('../img/background/background.jpg'); */
}

/* Custom style for navigation on the right */
#fp-nav {
  color: #fff;
}
#fp-nav ul li a span {
  background: #fff !important;
}

/* Items in content-wrapper */
.content-title {
  font-size: 11vh;
  line-height: 1.4;
  text-transform: uppercase;
}
.content-subtitle {
  font-size: 4vh;
  line-height: 1.1;
  margin-bottom: 1em;
}
.content-text {
  font-size: 2vh;
  line-height: 1.1;
  font-weight: bold;
}
