.navigation {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}

.navigation li {
  display: inline-block;
  margin: 10px 0;
  position: relative;
}

.navigation a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
  text-decoration: none;
  font-family: Montserrat;
}

.navigation li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

@media screen and (max-width: 800px) {
  .navigation {
    right: unset;
  }
}
