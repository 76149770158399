.logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  z-index: 99;
  font-family: Montserrat;
  font-size: 1.8em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

@media screen and (max-width: 800px) {
  .logo {
    display: none;
  }
}
