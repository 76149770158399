/* 
https://codepen.io/asna_farid/pen/ORJkZj?editors=1100
*/
.social-media {
  padding: 60px 0;
  text-align: center;
}

.social-media a svg {
  margin: 0 20px;
  color: white;
  cursor: pointer;
}

.social-media a svg:hover {
  -moz-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
